import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingBlock } from "../../components";
import { verifyEmail, unsubscribe } from "../../firebase/functions";

const STATUSES = { loading: "Loading", success: "Success", fail: "Fail" };

function EmailOperationBody({ status, message }) {
    return (
        <div className="body">
            <div className="sub-landing">
                <div className="title">{status}</div>
            </div>
            <div className="content-wrap center">
                {status === STATUSES.loading ? (
                    <LoadingBlock loading />
                ) : (
                    <p>{message}</p>
                )}
            </div>
        </div>
    );
}

export function EmailVerification() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [status, setStatus] = useState(STATUSES.loading);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (id) {
            const env = process.env.NODE_ENV || "production";
            verifyEmail({ env, id })
                .then(() => {
                    setStatus(STATUSES.success);
                    setMessage("Email successfully verified.");
                })
                .catch((err) => {
                    setStatus(STATUSES.fail);
                    setMessage(err.message);
                });
        } else {
            navigate("/");
        }
    }, [id, navigate]);

    return <EmailOperationBody status={status} message={message} />;
}

export function Unsubscribe() {
    const location = useLocation();
    const navigate = useNavigate();

    const [status, setStatus] = useState(STATUSES.loading);
    const [message, setMessage] = useState("");

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");

    useEffect(() => {
        if (email) {
            const env = process.env.NODE_ENV || "production";
            unsubscribe({ env, email })
                .then(() => {
                    setStatus(STATUSES.success);
                    setMessage("Unsubscribed successfully.");
                })
                .catch((err) => {
                    setStatus(STATUSES.fail);
                    setMessage(err.message);
                });
        } else {
            navigate("/");
        }
    }, [email, navigate]);

    return <EmailOperationBody status={status} message={message} />;
}
