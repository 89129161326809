import React, { useEffect } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import date from "date-and-time";
import { LinkButton, ShareButton } from "../../components";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";
import { LoadingBlock } from "../LoadingBlock/LoadingBlock";
import { Link } from "react-router-dom";
import "./SitePost.scss";

const DOMAIN =
    process.env.NODE_ENV === "production"
        ? "https://pmcusa.org"
        : "http://localhost:3000";

function CustomPageLayer(renderPageProps) {
    useEffect(() => {
        // Mark the page rendered completely when the canvas layer is rendered completely
        // So the next page will be rendered
        if (renderPageProps.canvasLayerRendered) {
            renderPageProps.markRendered(renderPageProps.pageIndex);
        }
    }, [renderPageProps, renderPageProps.canvasLayerRendered]);

    return (
        <>
            {renderPageProps.canvasLayer.children}
            {renderPageProps.annotationLayer.children}
        </>
    );
}

function formatFullDate(dt) {
    return (
        <span>
            <strong>{date.format(dt, "MMMM D, YYYY")}</strong> at{" "}
            <strong>{date.format(dt, "h:mm A")}</strong>
        </span>
    );
}

function PostHeader({ type, title, date, eventDetails, _id }) {
    const label = type === "event" ? "Event" : "Update";
    const link = `${DOMAIN}/posts/post/${_id}`;

    const handleClick = async () => {
        const link = `${DOMAIN}/posts/post/${_id}`;
        if (navigator.canShare) {
            await navigator.share();
        } else {
            await navigator.clipboard.writeText(link);
            alert(`Copied ${type} link to clipboard.`);
        }
    };

    let info;
    if (type === "event") {
        const { start, end, location } = eventDetails;
        info = (
            <div className="post-info">
                <EventHeaderDate start={start} end={end} />
                <div className="location">
                    <FontAwesomeIcon icon="map-marker-alt" className="icon" />
                    <div className="address">
                        <div className="line">{location}</div>
                    </div>
                </div>
            </div>
        );
    } else {
        info = (
            <div className="post-info">
                <div className="times">
                    Posted on {formatFullDate(date.published)}
                </div>
                {date.edited && date.edited > date.published && (
                    <div className="times">
                        Edited on {formatFullDate(date.edited)}
                    </div>
                )}
            </div>
        );
    }

    return (
        <header className="post__header">
            <div className="header__text">
                <div className="label">{label}</div>
                <div className="title">
                    <Link to={`/posts/post/${_id}`}>{title}</Link>
                </div>
                {info}
            </div>
            <div className="header__operations">
                <ShareButton
                    shareData={{
                        title: `Pannavasa Meditation Center ${label} - ${title}`,
                        url: link,
                    }}
                    shareText={`Share ${label}`}
                    copyText={`Copy ${label} Link`}
                />
            </div>
        </header>
    );
}

// startDate and endDate are 2 date objects
function EventHeaderDate({ start, end }) {
    if (
        start.getDate() === end.getDate() &&
        start.getMonth() === end.getMonth()
    ) {
        return (
            <div className="times">
                <div className="start">
                    On <strong>{date.format(start, "MMMM D, YYYY")}</strong>
                </div>
                <div className="end">
                    From <strong>{date.format(start, "h:mm A")}</strong> to{" "}
                    <strong>{date.format(end, "h:mm A")}</strong>
                </div>
            </div>
        );
    } else {
        return (
            <div className="times">
                <div className="start">From {formatFullDate(start)}</div>
                <div className="end">To {formatFullDate(end)}</div>
            </div>
        );
    }
}

function PostMedia({ media }) {
    if (media.length === 0) return null;
    return (
        <div className="media">
            {media.map((item, i) => {
                if (item.type === "image") {
                    return (
                        <img
                            src={item.url}
                            alt="Attached media for post."
                            key={i}
                            className="attachedImage"
                        />
                    );
                } else {
                    return (
                        <div className="pdfContainer" key={i}>
                            <Viewer
                                fileUrl={item.url}
                                renderPage={(props) => (
                                    <CustomPageLayer {...props} />
                                )}
                                renderLoader={(percent) => (
                                    <LoadingBlock loading>
                                        <div>PDF {percent}% loaded.</div>
                                    </LoadingBlock>
                                )}
                            />
                        </div>
                    );
                }
            })}
        </div>
    );
}

export function Event({ title, content, eventDetails, media, _id }) {
    return (
        <div className="post">
            <PostHeader
                type="event"
                title={title}
                _id={_id}
                eventDetails={eventDetails}
            />
            <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
            ></div>
            <PostMedia media={media} />
            {typeof eventDetails.ics === "string" && (
                <LinkButton
                    onClick={() =>
                        logEvent(analytics, "file_downloaded", {
                            file_name: title,
                            file_type: "ics",
                        })
                    }
                    type="native"
                    color="black"
                    href={eventDetails.ics}
                >
                    Add Event to Calendar
                </LinkButton>
            )}
        </div>
    );
}

export function Update({ title, date, content, media, _id }) {
    return (
        <div className="post">
            <PostHeader type="update" title={title} _id={_id} date={date} />
            <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
            ></div>
            <PostMedia media={media} />
        </div>
    );
}
