import React, { useState, useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { UpdatingScrollable, Album } from "../../components";
import {
    subscribeToAlbums,
    subscribeToDocCounts,
    analytics,
} from "../../firebase";

const ALBUMS_PER_LOAD = 3;

export default function Gallery() {
    const [albums, setAlbums] = useState([]);
    const [totalAlbumCount, setTotalAlbumCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [queryEnd, setQueryEnd] = useState(ALBUMS_PER_LOAD);

    useEffect(() => {
        logEvent(analytics, "page_view", {
            page_title: `Gallery (${process.env.NODE_ENV})`,
            page_path: "/gallery",
        });
        return subscribeToDocCounts((counts) =>
            setTotalAlbumCount(counts.albums)
        );
    }, []);

    useEffect(() => {
        setLoading(true);
        return subscribeToAlbums(
            (albums) => {
                setLoading(false);
                setAlbums(albums);
            },
            { type: "where", args: ["published", "==", true] },
            { type: "limit", args: [queryEnd] }
        );
    }, [queryEnd]);

    const handleBottomed = () => {
        if (!loading && queryEnd < totalAlbumCount) {
            setQueryEnd((prev) => prev + ALBUMS_PER_LOAD);
        }
    };

    return (
        <div className="body">
            <div className="sub-landing">
                <div className="title">Gallery</div>
            </div>
            <div className="section white gallery">
                <UpdatingScrollable
                    loading={loading}
                    onBottomed={handleBottomed}
                >
                    {albums.map((album, i) => (
                        <Album {...album} key={i} />
                    ))}
                </UpdatingScrollable>
                {queryEnd >= totalAlbumCount && (
                    <p style={{ textAlign: "center" }}>
                        No more albums to fetch.
                    </p>
                )}
            </div>
        </div>
    );
}
