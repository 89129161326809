import React, { Component } from "react";
import { logEvent } from "firebase/analytics";
import { subscribeToBooklets, analytics } from "../../firebase";
import "./Booklets.scss";

function Booklet({ name, url, uploadDate }) {
    const handleClick = () => {
        logEvent(analytics, "file_downloaded", {
            file_name: name,
            file_type: "pdf",
        });
    };

    return (
        <a
            onClick={handleClick}
            href={url}
            className="booklet"
            target="_blank"
            rel="noopener noreferrer"
        >
            <div className="name">{name}</div>
            <div className="date">
                Uploaded on{" "}
                {uploadDate.toLocaleString("en", {
                    dateStyle: "long",
                    timeStyle: "short",
                })}
            </div>
            <div className="page-len">Click to view/download.</div>
        </a>
    );
}

class Booklets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
        this.unsubscribe = null;
    }

    componentDidMount() {
        this.unsubscribe = subscribeToBooklets((booklets) => {
            this.setState({ items: booklets });
        });

        logEvent(analytics, "page_view", {
            page_title: `Booklets (${process.env.NODE_ENV})`,
            page_path: "/booklets",
        });
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    render() {
        return (
            <div className="body">
                <div className="sub-landing">
                    <div className="title">P.U.J.A. Booklets</div>
                </div>
                <div className="section white booklets">
                    <div className="content-wrap">
                        {this.state.items.map((booklet, i) => (
                            <Booklet
                                url={booklet.url}
                                name={booklet.title}
                                uploadDate={booklet.date}
                                key={i}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Booklets;
