import React, { useState, useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Album, LinkButton, LoadingBlock } from "../../components";
import { subscribeToAlbum } from "../../firebase";

export default function AlbumView() {
    const [album, setAlbum] = useState(null);

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        return subscribeToAlbum(id, (album) => {
            if (album && album.published) {
                setAlbum((prev) => {
                    if (prev === null) {
                        logEvent(analytics, "page_view", {
                            page_title: `Album ${album.name} (${process.env.NODE_ENV})`,
                            page_path: `/gallery/album/${id}`,
                        });
                    }
                    return album;
                });
            } else {
                navigate("/error", {
                    state: {
                        reason: "Album not found.",
                    },
                });
            }
        });
    }, [id, navigate]);

    return (
        <div className="body">
            <div className="sub-landing">
                <div className="title">Gallery</div>
            </div>
            <div className="section white gallery">
                <div style={{ width: "90%", maxWidth: 1600, margin: "auto" }}>
                    <LinkButton type="router" href="/gallery" color="gold">
                        <FontAwesomeIcon icon={faArrowLeft} /> Back
                    </LinkButton>
                </div>
                {album ? <Album {...album} /> : <LoadingBlock loading />}
            </div>
        </div>
    );
}
