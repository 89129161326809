import {
    query,
    where,
    collection,
    orderBy,
    limit,
    onSnapshot,
    addDoc,
    startAt,
    endAt,
    startAfter,
    endBefore,
    doc,
} from "firebase/firestore";
import { db } from "./config";

function path(collectionName) {
    const env = process.env.NODE_ENV || "production";
    return `/environments/${env}/${collectionName}`;
}

function toQueryConstraint(obj) {
    const { type, args } = obj;
    switch (type) {
        case "where":
            return where(...args);
        case "orderBy":
            return orderBy(...args);
        case "limit":
            return limit(...args);
        case "startAt":
            return startAt(...args);
        case "startAfter":
            return startAfter(...args);
        case "endAt":
            return endAt(...args);
        case "endBefore":
            return endBefore(...args);
        default:
            throw new Error("INVALID QUERY TYPE");
    }
}

function transformPost(snap) {
    if (snap.exists()) {
        const post = { ...snap.data() };
        delete post.emailRecipients;
        post._id = snap.id;
        post.date = {
            created: post.date.created.toDate(),
            edited: post.date.edited ? post.date.edited.toDate() : null,
            published: post.date.published
                ? post.date.published.toDate()
                : null,
        };
        if (post.type === "event") {
            post.eventDetails.start = post.eventDetails.start.toDate();
            post.eventDetails.end = post.eventDetails.end.toDate();
        }
        return post;
    } else {
        return null;
    }
}

function transformBooklet(snap) {
    const data = snap.data();
    return {
        ...data,
        date: data.date.toDate(),
        _id: snap.id,
    };
}

function transformAlbum(snap) {
    if (snap.exists()) {
        const data = snap.data();
        return {
            ...data,
            dateCreated: data.dateCreated.toDate(),
            dateEdited: data.dateEdited?.toDate(),
            _id: snap.id,
        };
    } else {
        return null;
    }
}

function transformPhoto(snap) {
    const data = snap.data();
    return {
        ...data,
        date: data.date.toDate(),
        _id: snap.id,
    };
}

export function subscribeToBooklets(callback) {
    const q = query(collection(db, path("booklets")), orderBy("date", "desc"));
    return onSnapshot(q, (snap) => {
        const docs = snap.docs.map(transformBooklet);
        callback(docs);
    });
}

export function subscribeToPosts(callback, ...queryItems) {
    const extraConstraints = queryItems.map(toQueryConstraint);
    const q = query(collection(db, path("posts")), ...extraConstraints);
    return onSnapshot(q, (snap) => {
        callback(snap.docs.map(transformPost));
    });
}

export function subscribeToPost(id, callback) {
    return onSnapshot(doc(db, path(`posts/${id}`)), (snap) => {
        callback(transformPost(snap));
    });
}

export function subscribeToUpcomingEvents(callback, n = 3) {
    return subscribeToPosts(
        callback,
        { type: "where", args: ["visible", "==", true] },
        { type: "where", args: ["type", "==", "event"] },
        { type: "where", args: ["eventDetails.start", ">", new Date()] },
        { type: "orderBy", args: ["eventDetails.start"] },
        { type: "limit", args: [n] }
    );
}

export async function addToEmailList(email, name = undefined) {
    const data = { email, date: new Date() };
    if (name) {
        data.name = name;
    }
    await addDoc(collection(db, path("temporaryEmails")), data);
}

export function subscribeToAlbums(callback, ...queryItems) {
    const constraints = queryItems.map(toQueryConstraint);
    return onSnapshot(
        query(collection(db, path("albums")), ...constraints),
        (querySnap) => callback(querySnap.docs.map(transformAlbum))
    );
}

export function subscribeToAlbum(id, callback) {
    return onSnapshot(doc(db, path(`albums/${id}`)), (snap) => {
        callback(transformAlbum(snap));
    });
}

export function subscribeToAlbumPhotos(albumID, callback, ...queryItems) {
    const constraints = queryItems.map(toQueryConstraint);
    return onSnapshot(
        query(collection(db, path(`albums/${albumID}/photos`)), ...constraints),
        (querySnap) => callback(querySnap.docs.map(transformPhoto))
    );
}

export function subscribeToDocCounts(callback) {
    return onSnapshot(
        doc(db, `environments/${process.env.NODE_ENV}`),
        (snap) => {
            callback(snap.data());
        }
    );
}
