import { useLocation } from "react-router-dom";

function ErrorPage() {
    const { state } = useLocation();
    return (
        <div className="body">
            <div className="sub-landing">
                <div className="title">Error</div>
            </div>
            <div className="content-wrap">
                <h6>It appears what you were looking for couldn't be found.</h6>
                {state?.reason && (
                    <p style={{ textAlign: "center", margin: "1rem 0" }}>
                        {state?.reason}
                    </p>
                )}
            </div>
        </div>
    );
}

export default ErrorPage;
