import React, { useState, useEffect, Fragment } from "react";
import { LoadingBlock } from "../LoadingBlock/LoadingBlock";

const OFFSET = 10;

export function UpdatingScrollable({ loading, onBottomed, children }) {
    const [bottomFlag, setBottomFlag] = useState(false);

    const handleScroll = () => {
        const scrollPos = window.scrollY + window.innerHeight;
        const atBottom = scrollPos >= document.body.scrollHeight - OFFSET;
        setBottomFlag((bottomFlag) => {
            if (atBottom && !bottomFlag) {
                return true;
            } else if (!atBottom && bottomFlag) {
                return false;
            }
            return bottomFlag;
        });
    };

    useEffect(() => {
        document.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (bottomFlag) {
            onBottomed();
            setBottomFlag(false);
        }
    }, [bottomFlag, onBottomed]);

    return (
        <Fragment>
            {children}
            <LoadingBlock loading={loading} />
        </Fragment>
    );
}
