import React, { useState } from "react";
import "./HamburgerButton.scss";

export function HamburgerButton({ onClick }) {
    const [toggled, setToggled] = useState(false);
    const handleClick = () => {
        setToggled((prev) => !prev);
        onClick();
    };
    const classNames = toggled
        ? "hamburger-button toggled"
        : "hamburger-button";
    return (
        <div className={classNames} onClick={handleClick}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar last"></div>
        </div>
    );
}
