import { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Worker } from "@react-pdf-viewer/core";
import { Navbar } from "./components/Navbar/Navbar";
import ROUTES from "./routes";
import "./App.scss";
import "react-image-lightbox/style.css";

export default function App() {
    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
            <div className="app">
                <Navbar />
                <Routes>
                    {ROUTES.filter((route) => route.path !== undefined).map(
                        (route, i) => {
                            let jsx = (
                                <Route
                                    path={route.path}
                                    element={route.element}
                                    key={i}
                                />
                            );
                            if (route.aliases) {
                                jsx = (
                                    <Fragment key={i}>
                                        {jsx}
                                        {route.aliases.map((path, j) => (
                                            <Route
                                                key={j}
                                                path={path}
                                                element={
                                                    <Navigate to={route.path} />
                                                }
                                            />
                                        ))}
                                    </Fragment>
                                );
                            }
                            return jsx;
                        }
                    )}
                </Routes>
            </div>
            <div className="footer">
                <div className="title">The Pannavasa Meditation Center</div>
                <div className="subtitle">&copy; Copyright 2019</div>
            </div>
        </Worker>
    );
}
