import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAOQZeO1jg5LcAAclEQMz8w0RBkXlg2Czw",
    authDomain: "pmcusa-646e1.firebaseapp.com",
    projectId: "pmcusa-646e1",
    storageBucket: "pmcusa-646e1.appspot.com",
    messagingSenderId: "297104231465",
    appId: "1:297104231465:web:61f70dfebe6d031f71a038",
    measurementId: "G-XP8FWT2CJV",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);
