import React, { Component, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import "./About.scss";
import compound from "../../images/about/compound.jpg";
import community1 from "../../images/about/community1.jpg";
import community2 from "../../images/about/community2.jpg";
import community3 from "../../images/about/community3.jpg";
import meditation from "../../images/about/meditation.jpg";
import founder from "../../images/about/founder.jpg";
import help from "../../images/help-img.jpg";
import pmcZelle from "../../images/qr/zelle-pmc.jpg";
import pmcMyanmar from "../../images/qr/zelle-myanmar.jpg";

export default function About() {
    useEffect(() => {
        logEvent(analytics, "page_view", {
            page_title: `About (${process.env.NODE_ENV})`,
            page_path: "/about",
        });
    }, []);

    return (
        <div className="body">
            <div className="sub-landing">
                <div className="title">
                    About The Pannavasa Meditation Center
                </div>
            </div>
            <div className="section white about">
                <div className="content-wrap centered">
                    <h3>Who Are We?</h3>
                    <div className="image-caption-group">
                        <img src={compound} alt="PMC Compound Building." />
                        <span>An image of the compound.</span>
                    </div>
                    <p>
                        The Pannavasa Meditation Center is the first and
                        foremost Burmese Theravada Buddhist Meditation Center
                        and a 501c3 non-profit organization serving Dallas/Fort
                        Worth area communities since July 2013. The meditation
                        center sits on 10+ acres of land surrounded by trees
                        providing natural tranquility and peacefulness essential
                        for Mindfulness meditation.
                    </p>
                </div>
            </div>
            <div className="section gold about">
                <div className="content-wrap centered">
                    <h3>Our Mission To The Community.</h3>
                    <p>
                        Our mission is to serve the surrounding Burmese and
                        Vietnames community through teaching important Theravada
                        Buddhist principles, such as Dharma and Vipassana
                        meditation, while educating and mentoring community
                        members and children.
                    </p>
                    <div className="image-caption-group">
                        <div className="image-grid">
                            <div className="grid-item">
                                <img src={community1} alt="Praying" />
                            </div>
                            <div className="grid-item">
                                <img src={community2} alt="Yearly festival" />
                            </div>
                            <div className="grid-item">
                                <img src={community3} alt="Offering food" />
                            </div>
                        </div>
                        <span>The community.</span>
                    </div>
                </div>
            </div>
            <div className="section white about">
                <div className="content-wrap">
                    <h3>What Do We Do?</h3>
                    <div className="flex-container meditation">
                        <div className="text">
                            <h6>Bi-Weekly Meditation Sessions</h6>
                            <p>
                                At our compound, we hold group Vipassana
                                meditation sessions every first and third Sunday
                                from 8:30 AM to 3:30 PM. Feel free to join us at
                                the compound and have lunch as well.
                            </p>
                        </div>
                        <div className="image">
                            <div className="image-caption-group">
                                <img
                                    src={meditation}
                                    alt="Meditation Session"
                                />
                                <span>A Meditation Session</span>
                            </div>
                        </div>
                    </div>
                    <div className="dharma">
                        <h6>Dharma Teachings</h6>
                        <p>
                            At the Pannavasa Meditation Center, we offer regular
                            lectures on Dharma. Our main outlet for teaching is
                            through weekly online talks every Saturday from 9:00
                            PM to 10:00 PM central time. Join us through Skype
                            to listen and ask questions or through our radio
                            service if you just want to listen in.{" "}
                        </p>
                        <div className="flex-container">
                            <IconContainer
                                icon={["fab", "skype"]}
                                title="Our Skype"
                            >
                                User: Upanna09
                            </IconContainer>
                            <IconContainer
                                icon={["fas", "headphones-alt"]}
                                title="Our Audio Stream"
                            >
                                <a href="https://pmcusa.org">
                                    Go to our stream here
                                </a>
                            </IconContainer>
                        </div>
                        <p>
                            We also give these Dharma lectures at our major
                            events.{" "}
                            <Link to="/updates">Follow our updates</Link> to
                            find out when we're hosting an event.
                        </p>
                    </div>
                    <div className="flex-container help">
                        <div className="image">
                            <div className="image-caption-group">
                                <img src={help} alt="H.E.L.P. Program" />
                                <span>
                                    The Health and Education Largesse Program,
                                    or H.E.L.P. for short.
                                </span>
                            </div>
                        </div>
                        <div className="text">
                            <h6>The H.E.L.P Organization</h6>
                            <p>
                                The Pannavasa Meditation Center has also
                                partnered with the Health and Education Largesse
                                Program (H.E.L.P), a program to help the Burmese
                                population here in Dallas adjust to and thrive
                                in their environment in the U.S. through
                                education.{" "}
                                <a
                                    href="http://helpdallas.org"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Go to their website
                                </a>{" "}
                                to see more and get involved!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section gold about">
                <div className="content-wrap biography">
                    <h3>About the Founder</h3>
                    <div className="image-caption-group">
                        <img src={founder} alt="Founder Pana Dipa Bhivasama" />
                        <span>The PMC Founder, Pana Dipa Bhivamsa.</span>
                    </div>
                    <p>
                        Sayadaw, or Pana Dipa Bhivamsa, is the founder of the
                        Pannavasa Meditation Center. Born in Kyi Pin Htu Village
                        in Sagaing, Myanmmar, he received a Buddhist education
                        as a young student. Obtaining three major degrees, he
                        was a professor at the Ma Soe Yaing Tite-Tit Monastery
                        in Mandalay and a speaker invited to TMC in San Jose,
                        California to teach Dhamma, Vipassana meditation, and
                        Theravada Buddhism. He founded the PMC in 2013 and has
                        since then worked to build it up.
                    </p>
                    <Expandable
                        expandedMessage="Click to hide the full bio."
                        hiddenMessage="Click to view the full bio."
                    >
                        <p>
                            Sayadaw was born in Sep 28, 1961 Kyi Pin Htu
                            village, Salingyi, Zagaing, Myanmar. He is the
                            youngest son among five brothers and sisters of U
                            Phe Tint, Daw Hla Tin. At age of twelve, he became a
                            novice monk (ko yin) with a preceptor U Pandita, the
                            abbot of Jotika Monastery and sponsored by his
                            parents. His name of novice is Shin Panna Dipa. At
                            age of twenty he became a fully ordained monk (Oo
                            Zin) at Khanda sima, Jotikarama Vihara, Myin Gyan,
                            Mandalay State sponsored by U Saw Maung Daw Than
                            family under the preceptor of Sayadaw gyi U Jagara,
                            the abbot of that monastery, Myin Gyan.
                        </p>
                        <p>
                            Sayadaw recieved a Buddhist education in his time of
                            study. From 1966 to 1973, he studied a basic Pali
                            Literature at the monastery of his native village.
                            From 1973 to 1981, He was studying the government’s
                            regular education at primary, middle, and high
                            school levels (a nge, a lat, a kyi) at Jotikarama
                            Vihara, Myin Gyan. From 1982 to 1988, he was able to
                            obtain three major degrees (a Sakya-siha
                            DhammaCariya, a Cetiyangana DhammaCariya, a
                            SaddhammaPala DhammaCariya. He changed his name
                            during this time period from Panna Dipa to Panna
                            Dipa Bhivamsa.
                        </p>
                        <p>
                            In 1988, he became a professor at the Ma Soe Yaing
                            Tite-Tit Monastery in Mandalay, giving lectures
                            about pali literatures, and he was one of the
                            contributing writers of the Pali-Myanmar Dictionary.
                            From 1994 to 1996, he practiced Vipassana meditation
                            at Shwe Taung Gon Sasana Yeik Thar, Yangon under the
                            guidance of U sasana (kan kaw myai Sayadaw), a
                            meditation master. While practicing, he gave
                            lectures to the various nuns and yogis residing
                            there.
                        </p>
                        <p>
                            In 1996, he was invited by TMC in San Jose,
                            California to teach Dhamma and meditation as well as
                            English. There in California, he gave lectures about
                            Theravada Buddhism, meditation, and abhidhamma at a
                            Sunday school.After recieving an Associates degree,
                            he ran a Sunday school class, abhidamma class, and
                            meditation retreats at Garland, TX as well as Bodhi
                            Meditation Center in Orlando, Florida.
                        </p>
                        <p>
                            In 2013, he founded the Pannavasa Meditation Center,
                            the first Myanmar monastery in the Dallas Fort Worth
                            area in Texas with the support of Vietnamese and
                            Burmese Families. He currently resides at the
                            Pannavasa Meditation Center as the abbot and
                            founder, supporting devotees by a way of religion,
                            spirituality, and social services.
                        </p>
                    </Expandable>
                </div>
            </div>
            <div className="section white about" id="donations">
                <div className="content-wrap donations">
                    <h3>Donations</h3>
                    <p>
                        PMC now has two Zelle accounts: one which goes to the
                        monastery and another that goes to help Myanmar. Info to
                        send money is listed below.
                    </p>
                    <div className="flex-container">
                        <a href={pmcZelle} target="_blank" rel="noreferrer">
                            <img
                                className="zelle"
                                src={pmcZelle}
                                alt="Zelle info for donating to PMC"
                            />
                        </a>
                        <a href={pmcMyanmar} target="_blank" rel="noreferrer">
                            <img
                                className="zelle"
                                src={pmcMyanmar}
                                alt="Zelle info for donating to PMC"
                            />
                        </a>
                    </div>
                    <p>
                        To sustain the Pannavasa Meditation Center, we solely
                        rely on donations from community members. We take these
                        through various means. Please consider giving on the
                        behalf of all of us.{" "}
                    </p>
                    <p>
                        Currently, we are trying to build a Dharma Hall
                        (meditation center) on our compound. Once finished, the
                        hall will be used for short and long Vipassana Retreats
                        which are important missions of Abbot Panna Dipa
                        Bhivamsa. Donations are especially imperative for this
                        project and all the more appreciated. Contact us at our
                        phone or email to find out more about the cost of the
                        project.
                    </p>
                    <div className="flex-container">
                        <IconContainer
                            icon={["fas", "money-check-alt"]}
                            title="Sending A Check"
                        >
                            Pay to The Pannavasa Meditation Corp.
                        </IconContainer>
                        <IconContainer
                            icon={["fas", "phone"]}
                            title="Over Phone"
                        >
                            Call us at (1) - 469 399-6644
                        </IconContainer>
                        <IconContainer
                            icon={["fas", "university"]}
                            title="Bank to Bank"
                        >
                            Email us at{" "}
                            <a href="mailto:pannavasa.vihara@gmail.com">
                                pmc.vihara@gmail.com
                            </a>
                        </IconContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}

class IconContainer extends Component {
    render() {
        return (
            <div className="icon-container">
                <FontAwesomeIcon className="icon" icon={this.props.icon} />
                <div className="info">
                    <div className="title">{this.props.title}</div>
                    <div className="label">{this.props.children}</div>
                </div>
            </div>
        );
    }
}

class Expandable extends Component {
    constructor(props) {
        super(props);
        this.state = { expanded: false };
        this.handleExpandClick = this.handleExpandClick.bind(this);
    }

    handleExpandClick() {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    }

    render() {
        const { expanded } = this.state;
        return (
            <div className="expandable">
                <div className="button" onClick={this.handleExpandClick}>
                    {expanded
                        ? this.props.expandedMessage
                        : this.props.hiddenMessage}
                </div>
                <div className={expanded ? "content expanded" : "content"}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
