import React, { Component, Fragment } from "react";
import date from "date-and-time";
import { logEvent } from "firebase/analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { subscribeToUpcomingEvents, analytics } from "../../firebase";
import "./HomeEventList.scss";
import { LoadingBlock } from "../LoadingBlock/LoadingBlock";

function EventItem({ title, eventDetails: { start, end, location, ics } }) {
    const day = date.format(start, "MMMM D, YYYY");
    const time =
        date.format(start, "h:mm A") + " – " + date.format(end, "h:mm A");

    const body = (
        <Fragment>
            <div className="desc">
                <div className="title">{title}</div>
                <div className="location">
                    <FontAwesomeIcon
                        size="xs"
                        className="icon"
                        icon="map-marker-alt"
                    />
                    <div className="lines">
                        <div className="line1">{location}</div>
                    </div>
                </div>
            </div>
            <div className="date">
                <div className="day">{day}</div>
                <div className="time">{time}</div>
            </div>
        </Fragment>
    );

    if (ics) {
        return (
            <a
                href={ics}
                className="event"
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                    logEvent(analytics, "file_downloaded", {
                        file_name: title,
                        file_type: "ics",
                    })
                }
            >
                {body}
            </a>
        );
    } else {
        return <div className="event">{body}</div>;
    }
}

export class HomeEventList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventList: [],
            loading: true,
        };
        this.unsubscribe = null;
    }

    componentDidMount() {
        this.unsubscribe = subscribeToUpcomingEvents((events) => {
            this.setState({
                eventList: events,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    render() {
        const { eventList, loading } = this.state;

        const loadedBody =
            eventList.length > 0 ? (
                <div className="event-list">
                    {eventList.map((event, i) => (
                        <EventItem {...event} key={i} />
                    ))}
                </div>
            ) : (
                <div className="no-events">
                    No upcoming events at this time.
                </div>
            );

        return (
            <div className="home-event-display">
                <h5>Upcoming Events</h5>
                {loading ? (
                    <LoadingBlock loading size={60} color={"#ffffff"} />
                ) : (
                    loadedBody
                )}
            </div>
        );
    }
}
