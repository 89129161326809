import {
    About,
    Booklets,
    Contact,
    EmailVerification,
    Unsubscribe,
    ErrorPage,
    Gallery,
    Home,
    Posts,
    AlbumView,
    PostView,
} from "./pages";

const routes = [
    {
        path: "/",
        element: <Home />,
        navbar: {
            label: "Home",
        },
    },
    {
        path: "/about",
        element: <About />,
        navbar: {
            label: "About",
        },
    },
    {
        path: "/contact",
        element: <Contact />,
        navbar: {
            label: "Contact",
        },
    },
    {
        path: "/booklets",
        element: <Booklets />,
        navbar: {
            label: "Booklets",
        },
    },
    {
        path: "/gallery",
        element: <Gallery />,
        navbar: {
            label: "Gallery",
        },
    },
    {
        path: "/gallery/album/:id",
        element: <AlbumView />,
    },
    {
        navbar: {
            label: "Donations",
            to: "/about#donations",
            type: "hash",
        },
    },
    {
        path: "/posts",
        element: <Posts />,
        navbar: {
            label: "Events and Updates",
        },
        aliases: ["/events", "/updates"],
    },
    {
        path: "/posts/post/:id",
        element: <PostView />,
    },
    {
        path: "/verify_email/:id",
        element: <EmailVerification />,
    },
    {
        path: "/unsubscribe",
        element: <Unsubscribe />,
    },
    {
        path: "/error",
        element: <ErrorPage />,
        aliases: ["*"],
    },
];

export default routes;
