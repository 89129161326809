import React, { useState, useEffect } from "react";
import { logEvent } from "firebase/analytics";
import {
    analytics,
    subscribeToDocCounts,
    subscribeToPosts,
} from "../../firebase";
import {
    UpdatingScrollable,
    UpdateForm,
    Event,
    Update,
} from "../../components";
// import "./Posts.scss";

const POSTS_PER_LOAD = 3;

export default function Posts() {
    const [posts, setPosts] = useState([]);
    const [totalPosts, setTotalPosts] = useState(0);
    const [queryEnd, setQueryEnd] = useState(POSTS_PER_LOAD);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        logEvent(analytics, "page_view", {
            page_title: `Post (${process.env.NODE_ENV})`,
            page_path: "/post",
        });
        return subscribeToDocCounts((counts) => setTotalPosts(counts.posts));
    }, []);

    useEffect(() => {
        setLoading(true);
        return subscribeToPosts(
            (posts) => {
                setLoading(false);
                setPosts(posts);
            },
            { type: "where", args: ["visible", "==", true] },
            { type: "orderBy", args: ["date.published", "desc"] },
            { type: "limit", args: [queryEnd] }
        );
    }, [queryEnd]);

    const handleBottomed = () => {
        if (!loading && queryEnd < totalPosts) {
            setQueryEnd((prev) => prev + POSTS_PER_LOAD);
        }
    };

    return (
        <div className="body">
            <div className="sub-landing">
                <div className="title">Updates and Events</div>
            </div>
            <div className="section white signup-form">
                <div className="content-wrap">
                    <p style={{ textAlign: "center" }}>
                        To get updates directly from us, subscribe to our
                        mailing list! You can unsubscribe whenever you please.
                    </p>
                    <UpdateForm color="black" />
                </div>
            </div>
            <div className="section white update-list">
                <div className="content-wrap">
                    <UpdatingScrollable
                        loading={loading}
                        onBottomed={handleBottomed}
                    >
                        {posts.map((data, i) => {
                            // Figure out whether this is an update or event
                            if (data.type === "event") {
                                return <Event {...data} key={i} />;
                            } else {
                                return <Update {...data} key={i} />;
                            }
                        })}
                    </UpdatingScrollable>
                    {queryEnd >= totalPosts && (
                        <p style={{ textAlign: "center" }}>
                            No more posts to fetch.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}
