import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import { LoadingBlock } from "../LoadingBlock/LoadingBlock";
import { subscribeToAlbumPhotos } from "../../firebase";
import { ShareButton } from "../ShareButton/ShareButton";
import "./Album.scss";

const DOMAIN =
    process.env.NODE_ENV === "production"
        ? "https://pmcusa.org"
        : "http://localhost:3000";

export function Album({ name, dateCreated, dateEdited, _id }) {
    const [photos, setPhotos] = useState(null);
    const [lightbox, setLightbox] = useState({
        open: false,
        index: -1,
    });

    useEffect(() => {
        subscribeToAlbumPhotos(_id, setPhotos, {
            type: "orderBy",
            args: ["date"],
        });
    }, [_id]);

    const handlePhotoClick = (i) => {
        setLightbox({ open: true, index: i });
    };

    const date = dateEdited || dateCreated;
    const dateString = `${
        dateEdited ? "Edited" : "Created"
    } ${date.toLocaleString("en", {
        dateStyle: "long",
        timeStyle: "short",
    })}`;

    let lightboxJSX = null;
    if (lightbox.open) {
        const i = lightbox.index;
        const curr = photos[i],
            prev = photos[i - 1],
            next = photos[i + 1];
        lightboxJSX = (
            <Lightbox
                mainSrc={curr.url}
                mainSrcThumbnail={curr.thumbnail}
                prevSrc={prev?.url}
                prevSrcThumbnail={prev?.thumbnail}
                nextSrc={next?.url}
                nextSrcThumbnail={next?.thumbnail}
                imageCaption={
                    <Fragment>
                        <p style={{ fontSize: 12, fontStyle: "italic" }}>
                            Uploaded{" "}
                            {curr.date.toLocaleString("en", {
                                dateStyle: "long",
                                timeStyle: "short",
                            })}
                        </p>
                        {curr.caption && <p>{curr.caption}</p>}
                    </Fragment>
                }
                onMoveNextRequest={() =>
                    setLightbox({ ...lightbox, index: i + 1 })
                }
                onMovePrevRequest={() =>
                    setLightbox({ ...lightbox, index: i - 1 })
                }
                onCloseRequest={() => setLightbox({ open: false, index: -1 })}
            />
        );
    }

    return (
        <div className="album">
            <header className="album__header">
                <div className="header__text">
                    <h5 className="album__title">
                        <Link to={`/gallery/album/${_id}`}>{name}</Link>
                    </h5>
                    <div className="album__date">{dateString}</div>
                </div>
                <div className="header__operations">
                    <ShareButton
                        shareData={{
                            title: `Pannavasa Meditation Center Album - ${name}`,
                            url: `${DOMAIN}/gallery/album/${_id}`,
                        }}
                        shareText="Share Album"
                        copyText="Copy Album Link"
                    />
                </div>
            </header>
            {photos instanceof Array ? (
                <div className="album__photos">
                    {photos.map((photo, i) => (
                        <Photo
                            {...photo}
                            key={i}
                            onClick={() => handlePhotoClick(i)}
                        />
                    ))}
                </div>
            ) : (
                <LoadingBlock loading>
                    <p>Loading Photos...</p>
                </LoadingBlock>
            )}
            {lightboxJSX}
        </div>
    );
}

function Photo({ thumbnail, caption, onClick }) {
    return (
        <button className="photo" onClick={onClick}>
            <img src={thumbnail} alt={caption || "Autogenerated thumbnail"} />
        </button>
    );
}
