import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./LinkButton.scss";

export function LinkButton({ type, onClick, href, children, color = "black" }) {
    const className = "link-button btn-" + color;
    const props = { onClick, className };
    let link;
    switch (type) {
        case "hash":
            link = (
                <HashLink {...props} to={href}>
                    {children}
                </HashLink>
            );
            break;
        case "router":
            link = (
                <Link {...props} to={href}>
                    {children}
                </Link>
            );
            break;
        case "external":
        case "native":
            link = (
                <a
                    {...props}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {children}
                </a>
            );
            break;
        default:
            throw new Error("Invalid link type.");
    }
    return link;
}
