import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import "./Contact.scss";

function ContactInfoCard(props) {
    return (
        <div className="contact-info-card">
            <FontAwesomeIcon className="icon" icon={props.icon} />
            <div className="desc">
                <div className="title">{props.title}</div>
                <div className="info">{props.children}</div>
            </div>
        </div>
    );
}

export default function Contact() {
    useEffect(() => {
        logEvent(analytics, "page_view", {
            page_title: `Contact (${process.env.NODE_ENV})`,
            page_path: "/contact",
        });
    }, []);

    return (
        <div className="body">
            <div className="sub-landing">
                <div className="title">Contact</div>
            </div>
            <div className="section white contact">
                <h6>Get in touch!</h6>
                <div className="content-wrap">
                    <div className="contact-info">
                        <ContactInfoCard
                            title="Our Facebook"
                            icon={["fab", "facebook-f"]}
                        >
                            <a
                                href="https://www.facebook.com/PannavasaDallas/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Link Here
                            </a>
                        </ContactInfoCard>
                        <ContactInfoCard
                            title="Our Email"
                            icon={["fas", "envelope"]}
                        >
                            <a href="mailto:pmc.vihara@gmail.com">
                                pmc.vihara@gmail.com
                            </a>
                        </ContactInfoCard>
                        <ContactInfoCard
                            title="Our Address"
                            icon={["fas", "map-marker-alt"]}
                        >
                            7505 Fairport Rd
                            <br />
                            Dallas, Texas 75217
                        </ContactInfoCard>
                        <ContactInfoCard
                            title="Our Phone"
                            icon={["fas", "phone-alt"]}
                        >
                            <a href="tel:14693996644">(469) 399-6644</a>
                        </ContactInfoCard>
                    </div>
                </div>
            </div>
        </div>
    );
}
