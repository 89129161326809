import React, { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import { LinkButton, HomeEventList, UpdateForm } from "../../components";
import logo from "../../images/logo.png";
import about_img from "../../images/about-img.jpg";
import help_img from "../../images/help-img.jpg";
import "./Home.scss";

export default function Home() {
    useEffect(() => {
        logEvent(analytics, "page_view", {
            page_title: `Home (${process.env.NODE_ENV})`,
            page_path: "/home",
        });
    });
    return (
        <div className="body">
            <div className="home-landing">
                <div className="logo">
                    <img src={logo} alt="PMC Logo" />
                    <div className="title">The Pannavasa Meditation Center</div>
                </div>
                <div className="divider"></div>
                <div className="tagline">
                    Dedicated to teaching peacefulness, mindfulness, and
                    tranquility.
                </div>
            </div>
            <div className="section white about">
                <div className="content-wrap">
                    <h3>About Us</h3>
                    <div className="flex-wrapper">
                        <div className="img-flexwrap">
                            <img
                                className="about-img"
                                src={about_img}
                                alt="PMC monks walking."
                            />
                        </div>
                        <div className="text-desc about-desc">
                            <p>
                                The Pannavasa Meditation Center is the first and
                                foremost Burmese Theravada Buddhist Meditation
                                Center and a 501c3 non-profit organization
                                serving Dallas/Fort Worth area communities since
                                July 2013. The meditation center sits on 10+
                                acres of land surrounded by trees providing
                                natural tranquility and peacefulness essential
                                for Mindfulness meditation.
                            </p>
                            <p>
                                We aim to give back to the community through
                                teaching Dharma and Vipassana Meditation as well
                                as educating and donating to the Buddhist and
                                Vietnamese communties around us through programs
                                such as H.E.L.P., bi-weekly weekend group
                                meditation sessions, and online Dharma lectures.{" "}
                            </p>
                            <LinkButton
                                color="black"
                                href="/about"
                                type="router"
                            >
                                Learn More About Us.
                            </LinkButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section gold updates">
                <div className="content-wrap">
                    <h3>Updates and Events</h3>
                    <p>
                        The latest things happening at the Pannavasa Meditation
                        center. See more by subscribing to our mailing list!
                    </p>
                    <UpdateForm color="gold" />
                    <HomeEventList />
                </div>
            </div>
            <div className="section white help">
                <div className="content-wrap">
                    <h3>H.E.L.P. Dallas</h3>
                    <div className="flex-wrapper">
                        <div className="text-desc help-desc">
                            <p>
                                The Pannavasa Meditation Center has also
                                partnered with the Health and Education Largesse
                                Program (H.E.L.P), a program to help the Burmese
                                population here in Dallas adjust to and thrive
                                in their environment in the U.S. through
                                education. Volunteers are much appreciated!
                            </p>
                            <LinkButton
                                type="native"
                                color="black"
                                href="https://dallashelp.org/"
                                onClick={() =>
                                    logEvent(analytics, "goto_external_site", {
                                        navigate_location:
                                            "https://dallashelp.org",
                                    })
                                }
                            >
                                Go to the HELP Website
                            </LinkButton>
                        </div>
                        <div className="img-flexwrap">
                            <img
                                className="help-img"
                                src={help_img}
                                alt="H.E.L.P. Dallas tutors teaching students."
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
