import { ClipLoader } from "react-spinners";

export function LoadingBlock({
    style,
    loading,
    size = 48,
    color = "#c0525e",
    children,
}) {
    return (
        <div style={{ margin: "auto", textAlign: "center", ...style }}>
            <ClipLoader loading={loading} size={size} color={color} />
            {children}
        </div>
    );
}
