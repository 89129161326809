import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { HamburgerButton } from "../HamburgerButton/HamburgerButton";
import ROUTES from "../../routes";
import "./Navbar.scss";

export class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuExpanded: false,
            isAtTop: true,
        };
        this.expandHamburgerMenu = this.expandHamburgerMenu.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    expandHamburgerMenu() {
        const menuToggled = this.state.menuExpanded;
        this.setState({ menuExpanded: !menuToggled });
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll() {
        const scrollPos =
            document.body.scrollTop || document.documentElement.scrollTop;
        this.setState({ isAtTop: scrollPos === 0 });
    }

    render() {
        const { isAtTop, menuExpanded } = this.state;
        return (
            <div className={isAtTop ? "nav" : "nav scrolling"}>
                <div className="hamburger-con">
                    <HamburgerButton onClick={this.expandHamburgerMenu} />
                </div>
                <ul className={menuExpanded ? "expanded" : ""}>
                    {ROUTES.filter((route) => route.navbar !== undefined).map(
                        (route, i) => {
                            const Link =
                                route.navbar.type === "hash"
                                    ? HashLink
                                    : NavLink;
                            const to = route.navbar.to || route.path;
                            return (
                                <li key={i}>
                                    <Link
                                        to={to}
                                        onClick={() =>
                                            this.setState({
                                                menuExpanded: false,
                                            })
                                        }
                                    >
                                        {route.navbar.label}
                                    </Link>
                                </li>
                            );
                        }
                    )}
                </ul>
            </div>
        );
    }
}
