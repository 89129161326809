import { httpsCallable } from "firebase/functions";
import { functions } from "./config";

class CustomError extends Error {
    constructor(message, prefix) {
        super(message);
        this.name = `${prefix}Error`;
    }
}

const callVerifyEmail = httpsCallable(functions, "verifyEmail");
const callUnsubscribeEmail = httpsCallable(functions, "unsubscribeEmail");

/**
 *
 * @param {Object} params
 * @param {string} params.env
 * @param {string} params.id
 */
export async function verifyEmail(params) {
    const { data } = await callVerifyEmail(params);
    if (data.status === "error") {
        const { type, message } = data.error;
        throw new CustomError(message, type);
    }
}

/**
 *
 * @param {Object} params
 * @param {string} params.env
 * @param {string} params.email
 */
export async function unsubscribe(params) {
    const { data } = await callUnsubscribeEmail(params);
    if (data.status === "error") {
        const { type, message } = data.error;
        throw new CustomError(message, type);
    }
}
