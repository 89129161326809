import React, { useState, useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { analytics, subscribeToPost } from "../../firebase";
import { Event, Update, LoadingBlock, LinkButton } from "../../components";

export default function PostView() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [post, setPost] = useState(null);

    useEffect(() => {
        return subscribeToPost(id, (post) => {
            if (post || post.visible) {
                setPost((prev) => {
                    if (prev === null) {
                        logEvent(analytics, "page_view", {
                            page_title: `Individual ${post.type} (${process.env.NODE_ENV})`,
                            page_path: `/posts/post/${id}`,
                        });
                    }
                    return post;
                });
            } else {
                navigate("/error", { state: { reason: "Post not found." } });
            }
        });
    }, [id, navigate]);

    return (
        <div className="body">
            <div className="sub-landing">
                <div className="title">Updates and Events</div>
            </div>
            <div className="section white update-list">
                <div className="content-wrap">
                    <LinkButton type="router" href="/posts" color="black">
                        <FontAwesomeIcon icon="arrow-left" /> Back
                    </LinkButton>
                    {post ? (
                        post.type === "event" ? (
                            <Event {...post} />
                        ) : (
                            <Update {...post} />
                        )
                    ) : (
                        <LoadingBlock loading />
                    )}
                </div>
            </div>
        </div>
    );
}
