import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import App from "./App";

// Adding icons for fontawesome

library.add(fas, fab);

ReactDOM.createRoot(document.getElementById("root")).render(
    <Router>
        <App />
    </Router>
);
