import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import "./ShareButton.scss";

export function ShareButton({
    copyText,
    shareText,
    shareData: { title, text, url },
    color = "black",
}) {
    const shareAvailable = navigator.canShare !== undefined;

    const handleClick = async () => {
        if (shareAvailable) {
            await navigator.share({ title, text, url });
        } else {
            await navigator.clipboard.writeText(url);
            alert(`Copied link to clipboard.`);
        }
        logEvent(analytics, "share", {
            method: shareAvailable ? "Share Screen" : "Copy",
            content_type: "link",
            item_id: url,
        });
    };

    let icon, buttonText;
    if (shareAvailable) {
        icon = "share";
        buttonText = shareText;
    } else {
        icon = "link";
        buttonText = copyText;
    }

    return (
        <button className={`shareButton ${color}`} onClick={handleClick}>
            <FontAwesomeIcon icon={icon} /> {buttonText}
        </button>
    );
}
