import React, { Component } from "react";
import { logEvent } from "firebase/analytics";
import { addToEmailList, analytics } from "../../firebase";
import "./UpdateForm.scss";

const EMAIL_REGEX =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export class UpdateForm extends Component {
    // Will make a POST request to add an email to the email list, after verifying whether a given email is valid.

    constructor(props) {
        super(props);
        this.state = {
            status: "",
            name: "",
            email: "",
            emailSubmited: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    handleTextChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    async handleSubmit() {
        // Verify if this submitted email is valid
        const { name, email } = this.state;
        if (!EMAIL_REGEX.test(email)) {
            this.setState({ status: "Email is invalid." });
        } else {
            // submit email
            const args = [email];
            if (name) args.push(name);
            await addToEmailList(...args);
            this.setState({
                emailSubmited: true,
                status: "Email successfully submitted! Look for a verification email in your inbox to receive correspondence.",
            });
            logEvent(analytics, "sign_up");
        }
    }

    render() {
        const { name, email, status, emailSubmited } = this.state;
        const disableSubmit = !EMAIL_REGEX.test(email);
        return (
            <div className={this.props.color + " update-form"}>
                {!emailSubmited && (
                    <div className="flex-wrap">
                        <input
                            className="update-form__textfield"
                            type="email"
                            name="email"
                            onChange={this.handleTextChange}
                            value={email}
                            placeholder="Place a valid email here. (e.g. example@email.com)"
                        />
                        <input
                            className="update-form__textfield"
                            type="text"
                            name="name"
                            onChange={this.handleTextChange}
                            value={name}
                            placeholder="OPTIONAL: Enter a name you want to be addressed as in correspondence."
                        />
                        <button
                            className="update-form__subscribe"
                            onClick={this.handleSubmit}
                            disabled={disableSubmit}
                        >
                            Subscribe
                        </button>
                    </div>
                )}
                <div className={status !== "" ? "feedback fadein" : "feedback"}>
                    {status}
                </div>
            </div>
        );
    }
}
